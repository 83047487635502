/** @module media controller */

import $ from 'jquery';
import 'slick-carousel';

const media = {
	

	init: function(){
		console.log('media controller start');
		this.slickInit();

	},
	slickInit: function(){
		$('.image-slider-container').slick({
			dots: true,
			fade: false,
			slide: 'li',
			cssEase: 'linear',
			centerMode: true,
			slidesToShow: 1,
			variableWidth: true,
			customPaging: function (slider, i) {
				return '<button class="tab">' + $('.slick-thumbs li:nth-child(' + (i + 1) + ')').html() + '</button>';
			},
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: false
					}
				}
			]
			
		});
		this.popupInit();
	},
	popupInit: function(){
		var t = this;
		$('.js-slick-popup').on('click', function(e){
			e.preventDefault();
			t.htmlForPopup($(this).data('picture-big'));
		})
		
	},
	htmlForPopup: function(img){
		
		let html = '<div class="bpc-popup">' +
						'<div class="bpc-popup--inner">' +
						'<span class="bpc-popup--inner--close">X</span>' +
						'<img src="' + img + '" class="bpc-popup--inner--image" />' +
					'</div></div>';
					
		$('body').append(html);
		this.closePopup();
	},
	closePopup: function(){
		$('.bpc-popup--inner--close').on('click', function(){
			$(this).parent().parent().remove();
		})
		$(document).keyup(function(e) {
		  if (e.keyCode === 27) $('.bpc-popup--inner--close').click();   // esc
		});
	}
	

}

	
window.addEventListener('load', () => {
	media.init();

})