/** @module menu controller */

import $ from 'jquery';

const menu = {
	
	
	init: function(){
		this.openMenu();
	},
	openMenu: function(){
		$('#nav-icon1').click(function () {
			$('header').toggleClass('open');
		});
	
	}

}

	
window.addEventListener('load', () => {
	menu.init();

})