/** @module text page controller */

import $ from 'jquery';


const textController = {
	
	htmlCiteButton: '<button class="cite">Cytuj</button>',
	
	init: function(){
		console.log('textController start');
		this.qControll();
		this.citeCoontroll();

	},
	qControll: function(){
		let elemArray 	= $('q'),
			t			= this;
		
		$(elemArray).on('click', function(){
			t.blockquoteControll($(this).data('quote'))
		})
	},
	blockquoteControll: function(data){
		let elemArray 	= $('blockquote'),
			t			= this;
		
		$(elemArray).map(function(index, elem){
			if($(elem).data('blockquote') == data){
				$(elem).addClass('open');
			}
			
		})
		this.closeBlockquote();
	},
	closeBlockquote: function(){
		let closeArray = $('.js-close');
		
		$(closeArray).on('click', function(){
			
			$(this).parent().removeClass('open');
		});
	},
	citeCoontroll: function(){
		
		let markArray 	= $('mark'),
			t			= this;
		
		$(markArray).map(function(index, elem){
			
			let heightElem 		= $(elem).parent().height(),
				positionElem	= $(elem).parent().position().top,
				rightElem		= $('.text-page--inner--right-container'),
				rightElemPos	= $('.text-page--inner--right-container').position().top,
				dataHtml		= $(elem).data('html'),
				position		= (positionElem - rightElemPos) + (heightElem / 2);
			
			$(rightElem).append('<div class="cite-box" style="top: ' + position +'px" data-html="' + dataHtml + '"><span>Strona ' + $(elem).data('page') + '</span>' + t.htmlCiteButton + '</div>');
			
		});
		this.popupHtml();
		
	},
	popupHtml: function(){
	
		var t = this;
		
		$('.cite').on('click', function(){
			let html = '<div class="bpc-popup cite"><div class="bpc-popup--inner">' + $(this).parent().data('html') + '<span class="bpc-popup--inner--close"></div></div>';
			$('body').append(html)
			t.closePopup();
		})
		
	},
	openPopup: function(){
		
	},
	closePopup: function(){
		
		$('.bpc-popup--inner--close').on('click', function(){
			$(this).parent().parent().remove();
			
		})
	}
	

}

	
window.addEventListener('load', () => {
	textController.init();

})