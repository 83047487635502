/** @module lists */

// spis treści wielostopniowy

import $ from 'jquery';



const lists = {
	
	
	init: function(){
		this.addArrow();
	},
	addArrow: function(){
		var elem = $('.single-element--inner--content--list li'),
			t = this;
		for(var i=0; i<$(elem).length; i++){
			if($(elem[i]).children('ol').length > 0){
				//$(elem[i]).addClass('arrow');
				$('<span class="arrow"></span>').insertAfter($(elem[i]).children('ol'));
			}
		}
		if($('.arrow').length == 0){
			$('.button-expand-all').hide();	
		}
		this.addClickEvent();
	},
	addClickEvent: function(){
		
		var root = this;
		
		$('.arrow').on('click', function(){
			var t = this;
			if(!$(this).prev().hasClass('open')){
				$(this).parent().addClass('expand');
				$(this).prev().addClass('open');
                $(this).prev().css('marginTop', '20px');
				$(this).prev().animate({ "max-height": 1000}, 500, 'linear');
				$
				root.findExpandElem();
			}else{
				$(this).prev().animate({ "max-height": 0}, 500, 'linear', function(){
					$(t).parent().removeClass('expand');
					$(t).parent().find('li').removeClass('expand');
					$(t).parent().find('ol').removeClass('open').removeAttr('style');
					$(t).prev().removeClass('open');
                    $(t).prev().css('marginTop', '0');
					root.findExpandElem();
				});
			}
		})
		this.expandAllElem();
	},
	findExpandElem: function(){
		
			
			if($('.button-expand-all').next().find('li:first-child').hasClass('expand')){
				$('.button-expand-all').text('Zwiń wszystko');
				
			}else{
				$('.button-expand-all').text('Rozwiń wszystko');
				
				
			}
	
		
	},
	expandAllElem: function(){
		//Rozwijanie wszystkich elementów z listy spisu treści
		
		let clickButton = $('.button-expand-all');
		var t = this;
		$(clickButton).on('click', function(){
			$('.arrow').trigger('click');
			t.findExpandElem();
		})
	}
}

window.addEventListener('load', () => {
	lists.init();
})