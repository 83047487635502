// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
                                // or other things that require babel runtime

import Preloader from './modules/preloader';
import $ from 'jquery';
import './modules/isMobile';
import './modules/ga';
import './modules/slider';
import './modules/menu';
import './modules/lists';
import './modules/mediaCotroller';
import './modules/textController';
//import './modules/ontouch';

window.addEventListener('load', () => {
   
    Preloader.hide();
	$('.close-popup').on('click', function(){	
		$(this).parent().parent().remove();
	})
	setTimeout(function(){
		//$('.close-popup').parent().parent().remove();
	}, 3000);
	
});
