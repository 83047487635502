/** @module slider */

// slider w kółko
// strzałki na klawiaturze

import $ from 'jquery';



const slider = {
	
	url: location.origin + '/pl/json/books?_format=json',
	activeElem: 0,
	
	init: function(){
		if($('#slider').length> 0){
			$('#slider').hide();
			this.getAjaxData();
			
		}
		
	},
	getAjaxData: function(){
		var t = this;
		$.ajax({
			url: t.url
		}).done(function(response) {
			
			t.data = response;
			t.iterator();
			t.rightArrow();
			t.leftArrow();
			t.keybordArrowController();
			t.swipeController();
			$('#slider').show();
		});
	},
	iterator: function(){
		var t = this;
		for(let i=0; i < this.data.length; i++){
			if(i == t.activeElem){
				
				t.firtsElemHtml(i);
				t.addRestHtml(i);
			}
		}
	},
	keybordArrowController: function(){
		
		$(document).keydown(function(e) {
			
			e = e || window.event;
			if (e.keyCode == '37') {
			   // left arrow
			   $('.main--slider--arrow--left').click();
			}
			else if (e.keyCode == '39') {
			   // right arrow
			   $('.main--slider--arrow--right').click();
			}
			
		});
		
	
	},
	calculationActiveElem: function(direction){
		
		if(direction == 'right'){
			if(this.activeElem == this.data.length-1){
				this.activeElem = 0;
			}else{
				this.activeElem ++;
			}
		}else{		
			if(this.activeElem == 0){
				this.activeElem = this.data.length-1
			}else{
				this.activeElem --;
			}
		}
		
	},
	swipeController: function(){
		var el = document.getElementById('first-elem-slider');
		var slideStop = false;
		this.onTouch(el, function(evt, dir, phase, swipetype, distance){
		// swipedir contains either "none", "left", "right", "top", or "down"
			//console.log(evt, dir, phase, swipetype, distance)
			if(dir == 'left' && !slideStop){
				slideStop = true;
				$('.main--slider--arrow--right').click();
			}else if(dir == 'right' && !slideStop){
				slideStop = true;
				$('.main--slider--arrow--left').click();	
			}else if(dir == 'up'){
				$('html, body').animate({scrollTop: $(window).scrollTop() + (distance * -1)},1);
			}else if(dir == 'down'){
				$('html, body').animate({scrollTop:$(window).scrollTop() + (distance * -1)},1);	
			}
			setTimeout(function(){
				slideStop = false
			},1000);
		})
	},
	rightArrow: function(){
		
		var t = this;
		$('.main--slider--arrow--right').on('click', function(){
			t.calculationActiveElem('right');
			t.hideElems();
			setTimeout(function(){
				t.iterator();
				setTimeout(function(){
					t.showElems();
				}, 250);
			}, 250);
			
		})
	},
	leftArrow: function(){
		var t = this;
		$('.main--slider--arrow--left').on('click', function(){
			t.calculationActiveElem('left');
			t.hideElems();
			setTimeout(function(){
				t.iterator();
				setTimeout(function(){
					t.showElems();
				}, 250);
			}, 250);
		})
		
		
	},
	firtsElemHtml: function(elem){

		var shortDesc = !!this.data[elem].field_short_description ? this.data[elem].field_short_description : '';


		const html = '<div class="first-elem-box"><a href="' + this.data[elem].path + '" class="image"><img src="' + this.data[elem].field_media_book_cover + '" alt="Okładka książki ' + this.data[elem].title + '" /></a><div class="content"><span>' + shortDesc + '<a href="' + this.data[elem].path + '">Czytaj dalej&nbsp;&raquo;</a></span></div></div>';
		$('#first-elem-slider').html(html);
		
	},
	restElemHtml: function(elem){
		
		
		return '<div class="first-elem-box"><a href="' + this.data[elem].path + '"><img src="' + this.data[elem].field_media_book_cover + '" alt="Okładka książki ' + this.data[elem].title + '" /></a></div>';
		
	},
	addRestHtml: function(elem){
		
		if(elem == this.data.length - 2){
			
			$('#second-elem-slider').html(this.restElemHtml(elem + 1));
			$('#last-elem-slider').html(this.restElemHtml(0));
			
		}else if(elem == this.data.length - 1){
			
			$('#second-elem-slider').html(this.restElemHtml(0));
			$('#last-elem-slider').html(this.restElemHtml(1));
			this.activeElem = this.data.length - 1;
		}else{
			
			$('#second-elem-slider').html(this.restElemHtml(elem + 1));
			$('#last-elem-slider').html(this.restElemHtml(elem + 2));	
		}
		
	},
	hideElems: function(){
		$('#first-elem-slider').addClass('hide-slide');
		$('#second-elem-slider').addClass('hide-slide');
		$('#last-elem-slider').addClass('hide-slide');
	},
	showElems: function(){
		$('#first-elem-slider').removeClass('hide-slide');
		$('#second-elem-slider').removeClass('hide-slide');
		$('#last-elem-slider').removeClass('hide-slide');
		
	},
	onTouch: function(el, callback){
		var touchsurface = el,
		dir,
		swipeType,
		startX,
		startY,
		distX,
		dist,
		distY,
		threshold = 500, //required min distance traveled to be considered swipe
		restraint = 500, // maximum distance allowed at the same time in perpendicular direction
		allowedTime = 500, // maximum time allowed to travel that distance
		elapsedTime,
		startTime,
		handletouch = callback || function(evt, dir, phase, swipetype, distance){}
	 
		touchsurface.addEventListener('touchstart', function(e){
			var touchobj = e.changedTouches[0]
			dir = 'none'
			swipeType = 'none'
			dist = 0
			startX = touchobj.pageX
			startY = touchobj.pageY
			startTime = new Date().getTime() // record time when finger first makes contact with surface
			handletouch(e, 'none', 'start', swipeType, 0) // fire callback function with params dir="none", phase="start", swipetype="none" etc
			e.preventDefault()
	 
		}, false)
	 
		touchsurface.addEventListener('touchmove', function(e){
			var touchobj = e.changedTouches[0]
			distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
			distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
			if (Math.abs(distX) > Math.abs(distY)){ // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
				dir = (distX < 0)? 'left' : 'right'
				handletouch(e, dir, 'move', swipeType, distX) // fire callback function with params dir="left|right", phase="move", swipetype="none" etc
			}
			else{ // else consider this a vertical movement
				
				dir = (distY < 0)? 'up' : 'down'
				handletouch(e, dir, 'move', swipeType, distY) // fire callback function with params dir="up|down", phase="move", swipetype="none" etc
			}
			e.preventDefault() // prevent scrolling when inside DIV
		}, false)
	 
		touchsurface.addEventListener('touchend', function(e){
			var touchobj = e.changedTouches[0]
			elapsedTime = new Date().getTime() - startTime // get time elapsed
			if (elapsedTime <= allowedTime){ // first condition for awipe met
				if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
					swipeType = dir // set swipeType to either "left" or "right"
				}
				else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
				
					swipeType = dir // set swipeType to either "top" or "down"
				}
			}
			// Fire callback function with params dir="left|right|up|down", phase="end", swipetype=dir etc:
			handletouch(e, dir, 'end', swipeType, (dir =='left' || dir =='right')? distX : distY)
			e.preventDefault()
		}, false)
	}
	
}

window.addEventListener('load', () => {
	slider.init();
})